import * as CookieConsent from "vanilla-cookieconsent";

CookieConsent.run({
    guiOptions: {
			consentModal: {
				layout: "box inline",
				position: "bottom center",
				// equalWeightButtons: false,
			},
			preferencesModal: {
				// layout: "box",
				// layout: 'bar wide',
        // position: 'left',
				// equalWeightButtons: false,
			}
    },
    categories: {
			necessary: {
				readOnly: true
			},
			analytics: {}
    },
    language: {
			default: "en",
			translations: {
				en: {
					consentModal: {
						title: "Cookie Consent",
						description: "Our website uses necessary cookies to ensure proper operation and tracking cookies to understand how you interact with it. The latter will only be enabled if you provide consent.",
						acceptAllBtn: "Accept All",
						acceptNecessaryBtn: "Accept Necessary",
						showPreferencesBtn: "Manage Cookies"
					},
					preferencesModal: {
						title: "Cookie Preferences",
						acceptAllBtn: "Accept All",
						acceptNecessaryBtn: "Accept Necessary",
						savePreferencesBtn: "Save Preferences",
						closeIconLabel: "Close Modal",
						serviceCounterLabel: "Service|Services",
						sections: [
							{
								description: "For more information on how we use cookies and other data, please read our <a href=\"/cookies/\">Cookie Policy</a>. You can change your preferences again from \"Manage Cookies\" in the website footer."
							},
							{
								title: "Necessary Cookies <span class=\"pm__badge\">Always Enabled</span>",
								description: "Functional cookies ensure all features and functions work properly, these cookies do not collect visitor information or activity.",
								linkedCategory: "necessary",
								cookieTable: {
									headers: {
										name: "Name",
										domain: "Service",
										description: "Description",
										expiration: "Expiration"
									},
									body: [
										{
											name: "cc_cookie",
											domain: "First Party",
											description: "Used to store your cookie consent preferences.",
											expiration: "6 months"
										},
										{
											name: "XSRF-TOKEN",
											domain: "First Party",
											description: "Prevents Cross-Site Request Forgery (CSRF) attacks. It helps enhance security by ensuring that each request made to the server is accompanied by a unique token, validating that the source of the request is legitimate and not from a malicious entity.",
											expiration: "Current session"
										},
										{
											name: "balmoral_castle_and_estate_session",
											domain: "First Party",
											description: "Stores session data required by the framework in order to work properly.",
											expiration: "Current session"
										},
										{
											name: "__cf_bm",
											domain: "CloudFlare",
											description: "Helps manage incoming traffic by distinguishing legitimate users from potential bots, enhancing security and performance on the website. This cookie is used to apply security settings on a per-client basis and does not track individual users across different websites.",
											expiration: "Current session"
										}
									]
								}
						},
						{
								title: "Analytics Cookies",
								description: "These cookies collect information to analyse traffic and provide valuable insights into how visitors use our website.",
								linkedCategory: "analytics",
								cookieTable: {
									headers: {
										name: "Name",
										domain: "Service",
										description: "Description",
										expiration: "Expiration"
									},
									body: [
										{
											name: "_ga",
											domain: "Google Analytics",
											description: "Used to distinguish visitor sessions. The data collected includes anonymised information and does not personally identify individual users.",
											expiration: "2 years"
										},
										{
											name: "_ga_*",
											domain: "Google Analytics",
											description: "Used to persist session state. This cookie does not store personally identifiable information",
											expiration: "2 years"
										}
									]
								}
							}
						]
					}
				}
			},
			autoDetect: "browser"
    }
});
